.flex-center-start{
    display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: start;
    -ms-flex-pack: start;
        justify-content: start;
}

%overlay{
	background-image: -moz-linear-gradient( 170deg, rgba(34,34,34,0) 0%, rgb(0,0,0) 100%);
	background-image: -webkit-linear-gradient( 170deg, rgba(34,34,34,0) 0%, rgb(0,0,0) 100%);
	background-image: -ms-linear-gradient( 170deg, rgba(34,34,34,0) 0%, rgb(0,0,0) 100%);
  }

  %btn_gradient{
    background-image: -moz-linear-gradient( 0deg, rgb(241,68,55) 0%, rgb(237,91,13) 99%);
    background-image: -webkit-linear-gradient( 0deg, rgb(241,68,55) 0%, rgb(237,91,13) 99%);
    background-image: -ms-linear-gradient( 0deg, rgb(241,68,55) 0%, rgb(237,91,13) 99%);
  }
  
  %haveProject{
    background-image: -moz-linear-gradient( 0deg, rgb(255,92,152) 0%, rgb(123,64,192) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(255,92,152) 0%, rgb(123,64,192) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(255,92,152) 0%, rgb(123,64,192) 100%);
  } 

  // left to right
  %gift{
    background-image: -moz-linear-gradient( 0deg, rgb(255,255,255) 0%, rgba(215,215,215,0.01) 99%, rgba(215,215,215,0) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(255,255,255) 0%, rgba(215,215,215,0.01) 99%, rgba(215,215,215,0) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(255,255,255) 0%, rgba(215,215,215,0.01) 99%, rgba(215,215,215,0) 100%);
   
  }
// Home Page Gradient

%gradient_team{
              /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#021a47+0,021a47+100&0+0,0.6+100 */
              background: -moz-linear-gradient(top,  rgba(2,26,71,0) 0%, rgba(2,26,71,0.6) 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top,  rgba(2,26,71,0) 0%,rgba(2,26,71,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom,  rgba(2,26,71,0) 0%,rgba(2,26,71,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00021a47', endColorstr='#99021a47',GradientType=0 ); /* IE6-9 */
}

%fradient_gallery{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#021a47+0,021a47+100&0+0,0.6+100 */
      background: -moz-linear-gradient(top, rgba(2,26,71,0) 0%, rgba(2,26,71,0.6) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(2,26,71,0) 0%,rgba(2,26,71,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(2,26,71,0) 0%,rgba(2,26,71,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00021a47', endColorstr='#99021a47',GradientType=0 ); /* IE6-9 */


}