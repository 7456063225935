// Hero
.hero-caption{
    & span{
        color: #fff;
        font-size: 16px;
        display: block;
        margin-bottom: 24px;
        font-weight: 600;
        padding-left: 95px;
        position: relative;
        &::before{
            position: absolute;
            content: "";
            width: 75px;
            height: 3px;
            background:$theme-color;
            left: 0;
            top: 52%;
            transform: translateY(-50%);
        }
    }
    & h2{
        color: #fff;
        font-size: 50px;
        font-weight: 700;
    }
}

