
// Header Bottom
.main-header{
    // header smoll device padding
    @media #{$md}{
       padding: 15px 0;
     }@media #{$sm}{
       padding: 15px 0;
     }
      @media #{$xs}{
       padding: 15px 0;
     }
    .main-menu{
        @media #{$laptop}{
           margin-right: 30px;
        }
        & ul{
            & li{
                display: inline-block;
                position: relative;
                z-index: 1;

                & a{
                    color: #002e5b;
                    font-weight: 400;
                    padding: 39px 19px;
                    font-weight: 400;
                    @media #{$lg}{
                        padding: 39px 15px;
                    }
                    display: block;
                    font-size: 16px;
                    @include transition(.3s);
                    text-transform: capitalize;
                   
                }
                &:hover{
                    & > a{
                        color:$theme-color;
                    }
                }
            }
            & ul.submenu{
                position: absolute;
                width: 170px;
                background: #fff;
                left: 0;
                top: 120%;
                visibility: hidden;
                opacity: 0;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
                padding: 17px 0;
                border-top: 5px solid $theme-color;
                border-radius: 0 37px 0px 24px;
                @include transition(.3s);
                & > li{
                    margin-left: 7px;
                    display: block;
                    & > a{
                        padding: 6px 10px !important;
                        font-size: 14px;
                        color: #0b1c39;
                        text-transform: capitalize;
                        &:hover{
                            color:$theme-color;
                            background: none;
                        }
                        & i{
                            // @include transition(.4s);
                        }
                    }
                } 
            }
        }
    }
    .logo{
        & img{
            
        }
    }
}

.header-area .header-top .header-info-right .header-social a i {
    @include transition(.4s);
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
}
.header-area .header-top .header-info-right .header-social a:hover i {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
}


.main-header ul > li:hover > ul.submenu {
	visibility: visible;
    opacity: 1;
    top: 100%;
}
 
.top-bg{
	background:#002d5b;
}

// header Sticky Responsive
.header-sticky ul li a {
	padding: 10px 19px;
}

.header-sticky.sticky-bar.sticky .main-menu ul>li>a {
	padding: 26px 20px;
}

.slicknav_menu .slicknav_icon-bar {
	background-color: $theme-color !important;
}
.slicknav_nav {
	margin-top: 0px;
}

// Sticky  Menu
.header-sticky.sticky-bar.sticky .header-btn {
        & .get-btn{
            padding: 20px 20px;
        }
    }
    .header-area .slicknav_btn {
        top: -34px;
    }
    .slicknav_menu .slicknav_nav a:hover {
        background: transparent;
        color: $theme-color;
    }
    .slicknav_menu {
        background: transparent;
        margin-top: 5px !important;
    }
    .mobile_menu {
        position: absolute;
        right: 0px;
        width: 100%;
        z-index: 99;
    }

